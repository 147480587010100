/* For main div */
*{margin: 0; padding:0;box-sizing: border-box;
}

.maincont{
   
    height: auto;
    
}
.cnote__cont{
    
}

.hlogo{height: 45px;
width: 50px;
margin-right: 10px;
margin-left: 5px;
}
.hbar__div{
    width: 100%;
    background-color:#282424;
    color: white;
    text-transform:capitalize;
    font-weight: 700;
}
.footer__div{
    position: fixed;
    bottom: 0;
    width: 100%;
  
    
}
.cnote__div{
width: 50%;
margin-top: 10px;
margin-left: auto;
margin-right: auto;
box-shadow: 0px 0px 8px 0px;
border-radius: 5px;
overflow: hidden;
height: auto;


}
.cnote__div label{
    font-weight: bold;
}

.input__msg{
    width: 100%;
    margin-top: 5px;
    resize: none;
    padding: 5px;
    height: 100%;
    
     
}
.input__title{
    width: 100%;
    padding: 5px;
    
}
form{
    
}


input::placeholder{
    font-size: large;
    font-weight: bolder;
}
textarea::placeholder{ font-size: medium; font-weight: 500;}

.cbtn{
    outline: none;
    border: none;
    transition-timing-function: ease;
    
}

.cbtn:hover{
    transform: scale(1.5);
    color: rgb(73, 105, 25);
    outline: none;
     border: none;
}

/* cardsssss */



.crdmain{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    box-shadow: 0px 0px 5px 0px;
}
.crdinput__title{
    width: 100%;
   
}
.crdinput__msg{
    margin-top: 5px;
    width: 100%;
    resize: none;
   
}
.crdbtn{
    outline: none;
    border: none;
    float: right;
    transform: translate(0px, -40px);
}
.crdbtni{
    color: darkgoldenrod;
}
.crdbtni:hover{
    color: crimson;
    transform: scale(1.5);
}